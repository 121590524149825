import { EventEmitter, Injectable } from '@angular/core';
import { Empresa } from 'src/model/empresa.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

  public modalProcessandoEvent: EventEmitter<boolean> = new EventEmitter();

  public nfseOpenPageEvent: EventEmitter<boolean> = new EventEmitter();

  public savedEmpresaEvent: EventEmitter<Empresa> = new EventEmitter();

}
